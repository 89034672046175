import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import "./index.scss"

const MaximoworldTitle = ({ _data, imgSrc = "" }) => {
  let result = {}
  try {
    const data = useStaticQuery(graphql`
      {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/(maximoworld-title)/" } }
        ) {
          nodes {
            exports {
              maximoworldTitle {
                description
              }
            }
          }
        }
      }
    `)
    result = _data || data.allMdx.nodes[0].exports.maximoworldTitle
  } catch (error) {
    result = _data
  }

  return result ? (
    <div
      id="MaximoworldTitledesc"
      className="MaximoworldTitledesc container mobilelayoutcontainer"
      data-testid="MaximoworldTitle"
    >
      {imgSrc && (
        <div id="MaximoworldTitle" className="MaximoworldTitle mt-3 ">
          <img className="image-responsive" src={imgSrc} alt="user-icon" />
        </div>
      )}
      <div id="description" className="description pt-3 mt-3 ">
        <p dangerouslySetInnerHTML={{ __html: result?.description }}></p>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default MaximoworldTitle
