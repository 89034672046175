import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Section1 from "./description-section/section1"
import TitleSection from "../maximoworld/maximoworld-title"
import MaximoworldSec2 from "../maximoworld/maximoworld-desc/section2"

import "./index.scss"
const MaximoMobile = () => {
  const titleDescription =
    "<strong>MAXapps</strong> is a <strong>Maximo mobile</strong> solution for <strong>Maximo EAM</strong>, it is built both with the assistance of industry experts and taking into account security, optimization and user experience."
  return (
    <div className="maximo-mobile" data-testid="MaximoMobile">
      <Helmet>
        <title>Maximo mobile - IBM Maximo mobile App</title>
        <meta name="robots" content="index, follow" />.
        <meta name="rights" content="© Copyright 2022 MAXapps Maximo Mobile" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.maxapps-maximo.com/maximo-mobile/"
        />
        <meta property="og:site_name" content="maxapps-maximo" />
        <meta property="og:locale" content="en-US" />
        <meta
          id="og-title"
          property="og:title"
          content={"maximo mobile - ibm maximo mobile app"}
        />
        <meta
          id="meta-description"
          name="description"
          content="IBM Maximo Mobile Solution for Your Enterprise. Build Powerful, Secure, Flexible and Versatile Maximo Mobile EAM Applications. MAXapps provides IBM Maximo teams with a flexible tool for generating dynamic mobile applications that can suit any business requirements."
        />
        <meta
          id="og-image"
          property="og:image"
          content="https://maxapps-maximo.com/og/image-header-maxapps.png"
        />
        <meta
          property="twitter:image:src"
          content="https://maxapps-maximo.com/og/image-header-maxapps.png"
        />
        <meta
          property="twitter:image"
          content="https://maxapps-maximo.com/og/image-header-maxapps.png"
        />
        <link
          rel="canonical"
          key="https://maxapps-maximo.com/maximo-mobile/"
          href="https://maxapps-maximo.com/maximo-mobile/"
        />
      </Helmet>
      <TitleSection
        _data={{ description: titleDescription }}
        imgSrc={"/image-header-maxapps.png"}
      />
      <Section1 />
      <div className="maximo-mobile-section2">
        <MaximoworldSec2 />
        <div className="row padding-100">
          <div className="col-md-6 align-content-right">
            <Link to="/">
              <button className="btn2 " type="button">
                Visit us
              </button>
            </Link>
          </div>
          <div className="col-md-6">
            <Link to="/contact/">
              <button className="btn " type="button">
                Contact us
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaximoMobile
