import React from "react"

import Layoutxl from "../components/layout/Layout-xl"
import MaximoMobile from "../components/maximo-mobile"

const MaximoMobilePage = () => {
  return (
    <Layoutxl>
      <MaximoMobile />
    </Layoutxl>
  )
}

export default MaximoMobilePage
