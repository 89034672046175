import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"

function MaximoworldSec2({ _MaximoworldDesc }) {
  let MaximoworldDesc = []
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/(maximoworld-sec2)/" } }
        ) {
          nodes {
            exports {
              MaximoworlSec2 {
                description
                srcIMG
                title
              }
            }
          }
        }
      }
    `)
    MaximoworldDesc = result.allMdx.nodes[0].exports.MaximoworlSec2
  } catch (error) {
    MaximoworldDesc = _MaximoworldDesc
  }
  return (
    <div
      className="MaximoworldSec2 row paddingClass container mobilelayoutcontainer"
      data-testid="WhyMaximoS3"
    >
      {MaximoworldDesc?.map((item, index) => {
        return (
          <div
            key={index}
            className="col-12 col-md-6 pb-4 pt-2 justify-content-center align-items-center"
          >
            <div className="col-12 px-md-3 pt-2 d-flex">
              <img
                src={item.srcIMG}
                alt={`IBM Maximo: ${item.title}`}
                title={`IBM Maximo: ${item.title}`}
              />
              <h2>
                <span>{item.title}</span>
              </h2>
            </div>

            <div className="col-12 px-md-3 pt-3">
              <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MaximoworldSec2
