import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import "./index.scss"
function Section1() {
  return (
    <div
      className="MaximoworldDesc container mobilelayoutcontainer"
      data-testid="WhyMaximoS3"
    >
      <div>
        <div className="row paddingClass">
          <div className="col-12 order-1 order-md-2 col-md-12 pt-3">
            <div className="mx-md-5 px-md-5 pb-5">
              <p className="mx-5 bold text-center">
                It comes with many features and functionality that can boost
                your business, we can split them into two categories, in-app
                features and others:
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section1
