import React from "react"

import FooterMain from "../../footer/footer-main"
import HeaderMain from "../../header/header-main"
import Seo from "../../seo"
import ZendeskButton from "../../zendesk"

import "./index.css"
const Layoutxl = ({ pageTitle = "", children }) => {
  return (
    <div className=" bg">
      <div className="container">
        <Seo title={pageTitle} />
        <HeaderMain />
      </div>
      {children}
      <div className="container">
        <FooterMain />
      </div>
      <ZendeskButton />
    </div>
  )
}

export default Layoutxl
